import React, { useState } from 'react';
import './login.css';

// Import Google icon SVG
import GoogleIcon from '../../assets/icon/google_icon.svg';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    try {
      // Redirect the user to the Google authentication page
      window.location.href = "http://localhost:5001/auth/google";
    } catch (error) {
      console.error('Error initiating Google authentication:', error);
      setErrorMessage('Failed to initiate Google authentication. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="modal_title">
        <h2>Hello!</h2>
        <div>Use your email or another service to continue with Coolors.</div>
      </div>
      <h2 className="login-title">Login Page</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button className="login-button" onClick={handleLogin}>
        <img src={GoogleIcon} alt="Google Icon" className="google-icon" />
        Login with Google
      </button>
      <iframe title="Google Authentication" src="http://localhost:5001/auth/google" style={{ display: 'none' }}></iframe>
    </div>
  );
};

export default Login;
