import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
// import AdminColor from '../AdminAdd/AdminColor'; 

function AdminHome() {
  return (
    <div className='Admin-container'>

<NavLink to="/admin-color" className="navlink-button"> {/* NavLink button */}
        Go to Admin Color
      </NavLink>


    <div className='admin-navbar'>

    </div>

    <div className='selct-navbar'>

    </div>
     
    </div>
  );
}

export default AdminHome;
