import React, { useState, useRef } from 'react';
import './ImageGenerator.css';
import ColorThief from 'colorthief';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ImageGenerator = () => {
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [selectedImage, setSelectedImage] = useState(null);
  const canvasRef = useRef(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      setError('');
      try {
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
        const palette = await extractColors(imageUrl);
        setColors(palette);
      } catch (error) {
        console.error('Error extracting colors:', error);
        setError('Error extracting colors. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const extractColors = async (imageUrl) => {
    const colorThief = new ColorThief();
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageUrl;
    const palette = await new Promise((resolve, reject) => {
      img.onload = () => {
        try {
          const colors = colorThief.getPalette(img, 8);
          const palette = colors.map(rgb => `rgb(${rgb.join(', ')})`);
          resolve(palette);
        } catch (error) {
          reject(error);
        }
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
    return palette;
  };

  const copyColorToClipboard = (color) => {
    navigator.clipboard.writeText(color);
    alert(`Copied ${color} to clipboard`);
  };

  const handleColorBoxClick = (color) => {
    setSelectedColor(color);
  };

  const handleImageClick = (event) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    const pixelData = context.getImageData(x, y, 1, 1).data;
    const rgbaColor = `rgba(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]}, ${pixelData[3] / 255})`;
    setSelectedColor(rgbaColor);
  };

  return (
    <div className="image-generator">
      {!selectedImage && (
        <>
          <h2>Extract color from an image and save them as color palette.</h2>
          <img className='upload' src='icon/upload.svg' alt='upload' title='image apload' />
          <h3>Drag and Drop Your File or Select a file from your computer</h3>
        </>
      )}
      <div className="file-upload-container">
        <input id="file-upload" type="file" accept="image/*" onChange={handleImageUpload} />
        <label htmlFor="file-upload" className="custom-file-upload">
          Select a file
        </label>
      </div>
      {selectedImage && (
        <div className="image-container">
          <img src={selectedImage} alt="Uploaded" className="uploaded-image" onClick={handleImageClick} />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
          <div className="color-palette">
  {colors.map((color, index) => (
    <div key={index} className="color-box" style={{ backgroundColor: color }} onClick={() => {
      handleColorBoxClick(color);
      navigator.clipboard.writeText(color);
      toast.success(`Copied ${color} to clipboard`);
    }}>
      <span className="color-value">{color}</span>
    </div>

            ))}
          </div>
        </div>
      )}
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default ImageGenerator;
