import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './pages/Home/Home';
import ColorGenerator from './pages/ColorGenerator/ColorGenerator';
import ImageGenerator from './pages/ImageGenerator/ImageGenerator';
import TrendingColor from './pages/TrendingColor/TrendingColor';
import Login from './pages/Login/login';
import AdminColor from './Admin/AdminAdd/AdminColor'; 
import AdminHome from './Admin/Home/AdminHome';

const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/login" />
  );
};

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/color-generator" element={ <ColorGenerator />}  />
      <Route path="/image-color-extractor" element={<ImageGenerator />}  />
      <Route path="/trending-color" element={<TrendingColor />}  />
      <Route path="/admin-color" element={<AdminColor />} />
      <Route path="/admin-home" element={<AdminHome />} />
    </Routes>
  );
};

export default RoutesConfig;






// import React from 'react';
// import { Routes, Route, Navigate } from 'react-router-dom'; // Import Navigate
// import { useSelector } from 'react-redux';
// import Home from './pages/Home/Home';
// import ColorGenerator from './pages/ColorGenerator/ColorGenerator';
// import ImageGenerator from './pages/ImageGenerator/ImageGenerator';
// import TrendingColor from './pages/TrendingColor/TrendingColor';
// import Login from './pages/Login/login';

// const ProtectedRoute = ({ element, ...rest }) => {
//   const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

//   return isAuthenticated ? (
//     element
//   ) : (
//     <Navigate to="/login" state={{ from: rest.location?.pathname }} /> // Access location.pathname safely
//   );
// };

// const RoutesConfig = (props) => { // Receive props
//   return (
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/login" element={<Login />} />
//       {/* Pass the rest props to the ProtectedRoute component */}
//       <Route path="/color-generator" element={<ProtectedRoute element={<ColorGenerator />} {...props} />} />
//       <Route path="/image-color-extractor" element={<ProtectedRoute element={<ImageGenerator />} {...props} />} />
//       <Route path="/trending-color" element={<ProtectedRoute element={<TrendingColor />} {...props} />} />
//     </Routes>
//   );
// };

// export default RoutesConfig;
