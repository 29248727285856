import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TrendingColorPalettes.css'; // Make sure to create this file for styling

const TrendingColorPalettes = ({ palettes }) => {
  const notifyCopied = (color) => {
    toast.success(`Copied ${color} to clipboard`, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const copyToClipboard = (color) => {
    navigator.clipboard.writeText(color);
    notifyCopied(color);
  };

  return (
    <div className="trending-color-palettes">
      
      <h2>Trending Color Palettes</h2>
      <p className='seo-text'>
           Discover trending color palettes for your design projects.<br/> Explore a curated collection of color combinations to inspire your next project.<br/> From vibrant hues to soothing shades, find the perfect palette to elevate your designs.
       </p>
      <div className="palettes-container">
        {palettes.map((palette, index) => (
          <div key={index} className="palette">
            <div className="colors">
              {palette.map((color, colorIndex) => (
                <div
                  key={colorIndex}
                  className="color-box"
                  style={{ backgroundColor: color }}
                  onClick={() => copyToClipboard(color)}>
                  <span className="color-value">{color}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingColorPalettes;
