import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ColorGenerator.css";

const ColorGenerator = () => {
  const [selectedColors, setSelectedColors] = useState([]);
  const [initialColors, setInitialColors] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [randomColorIndex, setRandomColorIndex] = useState(0);
  const colorSectionRef = useRef(null); // Reference for the color section

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const randomColors = Array.from({ length: 6 }, () => generateRandomColor());
    setSelectedColors(randomColors);
    setInitialColors(randomColors);
  }, []);
  
  const generateRandomColor = () => {
    const baseHue = Math.floor(Math.random() * 360); // Random base hue value between 0 and 360
    const baseSaturation = Math.floor(Math.random() * 50) + 50; // Random base saturation value between 50 and 100
    const baseLightness = Math.floor(Math.random() * 20) + 40; // Random base lightness value between 40 and 60
    
    const hueVariation = Math.floor(Math.random() * 30) - 15; // Random variation for hue between -15 and 15
    const saturationVariation = Math.floor(Math.random() * 20) - 10; // Random variation for saturation between -10 and 10
    const lightnessVariation = Math.floor(Math.random() * 20) - 10; // Random variation for lightness between -10 and 10
    
    // Ensure that the calculated values are within valid range
    const hue = (baseHue + hueVariation + 360) % 360; // Ensure hue is within 0-360 range
    const saturation = Math.min(Math.max(baseSaturation + saturationVariation, 0), 100); // Ensure saturation is within 0-100 range
    const lightness = Math.min(Math.max(baseLightness + lightnessVariation, 0), 100); // Ensure lightness is within 0-100 range
    
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };
  

  const updateRgbValues = (index, newColor) => {
    const updatedColors = [...selectedColors];
    updatedColors[index] = newColor;
    setSelectedColors(updatedColors);
  };

  const handleCopyToClipboard = (index) => {
    const colorInfo = `${selectedColors[index]}`;
    navigator.clipboard.writeText(colorInfo);
    toast.success(`Copied color to clipboard`);
  };

  const handleScreenshot = () => {
    html2canvas(colorSectionRef.current).then((canvas) => {
      const image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const link = document.createElement("a");
      link.download = "color_palette.png";
      link.href = image;
      link.click();

      toast.success('Screenshot captured!');
    });
  };

  const addColor = () => {
    const randomColor = generateRandomColor();
    setSelectedColors([...selectedColors, randomColor]);
    setInitialColors([...selectedColors, randomColor]);

    // Scroll to the bottom after adding a color
    colorSectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const removeColor = (index) => {
    const updatedColors = [...selectedColors];
    updatedColors.splice(index, 1);
    setSelectedColors(updatedColors);
    setInitialColors(updatedColors);
  };

// Your ColorGenerator component

const onDragEnd = (result) => {
  if (!result.destination) return;

  const newColors = [...selectedColors];
  const [reorderedItem] = newColors.splice(result.source.index, 1);
  newColors.splice(result.destination.index, 0, reorderedItem);

  setSelectedColors(newColors);
};


  const updateColors = () => {
    const newColors = Array.from({ length: 6 }, () => generateRandomColor());
    setSelectedColors(newColors);
    setInitialColors(newColors);
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * selectedColors.length);
    setRandomColorIndex(randomIndex);
  }, []);

  const handleColorInputClick = (event) => {
    event.preventDefault(); // Prevent the default behavior
    event.stopPropagation(); // Stop event propagation to prevent closing
  };

  useEffect(() => {
    const colorInputs = document.querySelectorAll('.color-input');
    colorInputs.forEach(input => {
      input.addEventListener('click', handleColorInputClick);
    });

    return () => {
      colorInputs.forEach(input => {
        input.removeEventListener('click', handleColorInputClick);
      });
    };
  }, []);

  return (
    <div className="color-generator-container">
      <div className="header-container">

      <header className="header">
        <div className="header-buttons">
          <div className="screenshot-button" onClick={handleScreenshot}>
            <img src="icon/camera.svg" alt="screenshot" />
          </div>
          <div className="update-button" onClick={updateColors}>
            <img src="icon/update.svg" alt="" />
          </div>
          <div className="add-color-button" onClick={addColor}>
            <img src="icon/add.svg" alt="add Color" />
          </div>
        </div>
      </header>
      </div>

      <div className="all-color" ref={colorSectionRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="color-section" direction={isMobile ? "vertical" : "horizontal"}>
            {(provided) => (
              <div
                className="color-section"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {selectedColors.map((color, index) => (
                  <Draggable
                    key={index}
                    draggableId={`color-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="color-generator"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <div
                          className="color-preview"
                          style={{ backgroundColor: color }}
                        >
                     
                          {hoveredIndex === index && (
                            <div className="color-menu">
                              <div className="input-section">
                               
                              </div>
                              <div
                                className="remove-color-button"
                                onClick={() => removeColor(index)}
                              >
                                <img
                                  src="icon/remove.svg"
                                  alt="Remove Color"
                                />
                              </div>
                              <div
                                className="copy-button"
                                onClick={() => handleCopyToClipboard(index)}
                              >
                                <img src="icon/copy.svg" alt="Copy Color" />
                              </div>
                              
                            </div>
                            
                          )}
                               <div className="click">
                            <img src="icon/click.svg" alt="" />
                          </div>
                          <div className="touch">
                            <img src="icon/touch.svg" alt="" />
                          </div>
                           <label htmlFor={`colorInput-${index}`}>
                                  <input
                                    id={`colorInput-${index}`}
                                    className="color-input"
                                    type="color"
                                    value={selectedColors[index]}
                                    onChange={(event) =>
                                      updateRgbValues(
                                        index,
                                        event.target.value
                                      )
                                    }
                                  />
                                </label>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ColorGenerator;
