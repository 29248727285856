import React, { useEffect, useRef, useState } from "react";
import "./Home.css"; // Import the CSS file for styling
import { Helmet } from "react-helmet"; // Import Helmet for managing document head contents
import { NavLink } from "react-router-dom";

const useIntersectionObserver = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.disconnect(); // Stop observing after the animation starts
        }
      });
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};

const Home = () => {
  const extractorRef = useRef(null);
  const rkSectionRef = useRef(null);
  const trendingColorRef = useRef(null);

  const isExtractorInView = useIntersectionObserver(extractorRef);
  const isRkSectionInView = useIntersectionObserver(rkSectionRef);
  const isTrendingColorInView = useIntersectionObserver(trendingColorRef);

  return (
    <div className="home-container">
      {/* Set page title and meta description */}
      <Helmet>
        <title>MyColor - Discover Colors and Create Stunning Designs</title>
        <meta
          name="description"
          content="Explore the world of colors with MyColor. Generate trending color palettes and create amazing images effortlessly. Join us today!"
        />
      </Helmet>

      <div className="color-home">
        <div className="welcome">
          <h1>
            Effortlessly generate colors in seconds with our Color Generator!{" "}
          </h1>
          <NavLink to="/color-generator">
          <button className="Trail-button">
          Trail for free
          </button>
          </NavLink>
          <div id="button-home-1" className="button-home-1"></div>
        </div>
        <NavLink to="/color-generator">
          <div className="video-container">
            <video id="myVideo" autoPlay loop muted>
              <source src="video/vidcolor.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </NavLink>
      </div>

      <section className={`rk-section ${isRkSectionInView ? "fadeInStart" : ""}`} ref={rkSectionRef}>
  <div className="rt-text">
    <h2>Transform Your Creative Projects with AI-MyColors</h2>
    <p>
      Explore a vast array of high-quality images and leverage our cutting-edge tools to elevate your artistic endeavors. Whether you need to use <strong>Image Restore</strong>, fill <strong>Generative Fill</strong>, remove unwanted objects with <strong>Object Remove</strong>, recolor elements using <strong>Object Recolor</strong>, or remove backgrounds with <strong>Background Remove</strong>, AI-MyColors has you covered.
    </p>
  </div>
  <div className="reklam-banner">
    <img src="Ai/ai.web" alt="AiMyColors Banner" />
    <a target="_blank" rel="noreferrer" href="https://ai.mycolors.pro/" className="reklam-link">Visit AI-MyColors</a>
  </div>
</section>


      <div className="color-home-2"></div>

      <section className={`color-extractor ${isExtractorInView ? "fadeInStart" : ""}`} ref={extractorRef}>
        <div className="color-home-3">
          <div className="color3-1">
            <NavLink to="/image-color-extractor" className="color3-1">
              <div className="extractor-img">
                <img
                  src="color/extractor.web"
                  alt="Expert Instructors"
                  className="welcome-3-img"
                  title="Expert Instructors - color School"
                />
              </div>
            </NavLink>
          </div>
          <div className="welcome-3">
            <h2>Image Color Extractor</h2>
            <p>Uncover the palette of your pictures with precision and ease!</p>
            <p>
              Ever wondered about the vibrant hues hiding within your images? Our Image Color Extractor unravels the mystery, revealing the spectrum of colors within any picture you upload. Whether you're a designer seeking inspiration, a photographer analyzing compositions, or simply curious about the color story behind your favorite images, our tool empowers you to explore and extract the essence of color.
            </p>
            <p>
              🔍 How It Works: Simply upload your image, and let our intelligent algorithms do the rest. We meticulously analyze every pixel, identifying the dominant colors, their hex codes, and even their prominence within the image. With our intuitive interface, you can visualize the color palette, download color swatches, or even export color data for seamless integration into your creative projects.
            </p>
          </div>
        </div>
      </section>

      <section className={`trending-color ${isTrendingColorInView ? "fadeInStart" : ""}`} ref={trendingColorRef}>
        <div className="color-home-4">
          <NavLink to="/trending-color">
            <div className="img-sect">
              <img
                src="color/color-and-beyond.web"
                alt="color5 and beyond"
                title="color5 and Beyond - color School"
              />
            </div>
          </NavLink>
          <div className="welcome-4">
            <h2>Trending Color</h2>
            <ul>
              <li>
                Dive into the latest in color trends with MyColor's Trending Color feature. Stay ahead of the curve and discover the most popular color palettes circulating the design world. From vibrant hues to subtle tones, our trending colors will inspire your next masterpiece. Join MyColor today and immerse yourself in the world of trending colors.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
