import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Axios'; // Import the customized axios instance
import './AdminColor.css'; // Import CSS file

function AdminColor() {
  const [colors, setColors] = useState([]);
  const [hexValues, setHexValues] = useState(['', '', '', '', '']); // State to store hex values for five inputs

  useEffect(() => {
    fetchColors();
  }, []);

  const fetchColors = async () => {
    try {
      const response = await axiosInstance.get('/colors/all-palettes'); // Use the customized axios instance
      setColors(response.data);
    } catch (error) {
      console.error('Error fetching colors:', error);
    }
  };

  const addColor = async () => {
    try {
      const response = await axiosInstance.post('/colors/trendcolor', { hex: hexValues[0] }); // Use the customized axios instance
      console.log('Color added successfully:', response.data.color);
      fetchColors(); // Refresh colors after adding
    } catch (error) {
      if (error.response && error.response.status === 500 && error.response.data && error.response.data.code === 11000) {
        console.error('Error adding color:', 'Duplicate color code. Please use a different hexadecimal color.');
      } else {
        console.error('Error adding color:', error);
      }
    }
  };

  const handleChange = (index, value) => {
    const newHexValues = [...hexValues];
    newHexValues[index] = value;
    setHexValues(newHexValues);
  };

  return (
    <div className="admin-color-container">
      <h2>Admin Color</h2>
      <div>
        {[0, 1, 2, 3, 4].map((index) => (
          <input
            key={index}
            type="text"
            placeholder={`Enter hex color ${index + 1}`}
            value={hexValues[index]}
            onChange={(e) => handleChange(index, e.target.value)}
          />
        ))}
        <button onClick={addColor}>Add Color</button>
      </div>
      <div>
        <h3>All Palettes</h3>
        {colors.map((palette, index) => (
          <div key={index}>
            <h4>Palette {index + 1}</h4>
            <ul>
              {palette.colors && palette.colors.map((color, colorIndex) => (
                <li key={colorIndex} style={{ backgroundColor: color.hex }}>{color.hex}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminColor;
