import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'; // Import Provider
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import store from './redux/store'; // Import your Redux store
import App from './App';
import './index.css';

const AppWithNotificationPrompt = () => {
  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else {
      if (Notification.permission === 'granted') {
        console.log('Permission to send notifications has already been granted');
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(function (permission) {
          if (permission === 'granted') {
            console.log('Permission to send notifications has been granted');
          }
        });
      }
    }
  }, []);

  return (
    <Provider store={store}> {/* Wrap your component tree with Provider */}
      <Router>
        <App />
        <ToastContainer />
      </Router>
    </Provider>
  );
};

ReactDOM.render(<AppWithNotificationPrompt />, document.getElementById('root'));
