import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    // Add other reducers here if you have more slices
  },
  // Add middleware, enhancers, and other configurations if needed
});

export default store;
